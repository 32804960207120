import { graphql } from "gatsby"
import React from "react"
import Navbar from "../components/navbar"
import * as styles from "./media.module.css"
import { GatsbyImage } from "gatsby-plugin-image"

export default function Images({ data }) {
 
  const nodes = data.allFile.nodes
  return (
    <div>
      <Navbar />
      <div className={styles.cards}>
        {nodes.map(node => (
          <div key={node.id}>
            <GatsbyImage
              image={node.childImageSharp.gatsbyImageData}
              style={{ with: "100%" }}
              alt="Bilder der Band"
            />
          </div>
        ))}
      </div>
    </div>
  )
}

export const query = graphql`
  query {
    allFile(
      filter: { absolutePath: { regex: "/media/images/" } }
      sort: { fields: absolutePath }
    ) {
      nodes {
        absolutePath
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
        id
      }
    }
  }
`
